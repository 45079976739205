import gql from 'graphql-tag';
import { DocumentTypeConfiguration } from '../../types/DocumentTypeConfiguration';

export interface DocumentConfigurationResponse {
  getDocumentTypeConfigurations: { configurations: DocumentTypeConfiguration[] };
}

export const GET_DOCUMENT_TYPE_CONFIG = gql`
  query getDocumentTypeConfigurations {
    getDocumentTypeConfigurations {
      configurations {
        documentType
        retentionPeriod
        forgetMeActions
        informationSecurityClassification
        exportSubjectData
        updateSubjectData
        deletable
      }
    }
  }
`;
