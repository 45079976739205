import React, { useEffect } from 'react';
import AdminContext, { useAdminContext } from './context';
import {
  GET_ADMIN_INFO,
  AdminInformationResponse
} from '~root/src/app/apollo/queries/GetAdminInformation';
import { UPDATE_DOCUMENT_TYPE_CONFIGURATION } from '../../apollo/mutations/UpdateDocumentTypeConfiguration';
import { DocumentTypeConfiguration } from '../../types/DocumentTypeConfiguration';
import {
  DocumentConfigurationResponse,
  GET_DOCUMENT_TYPE_CONFIG
} from '../../apollo/queries/GetDocumentConfiguration';
import { DELETE_DOCUMENT } from '../../apollo/mutations/DeleteDocument';
import { DeleteDocumentsRequest } from '../../types/DeleteDocumentsRequest';
import useDocHubAdminMutation from '../../hooks/Apollo/useDocHubAdminMutation';
import useLazyDocHubAdminQuery from '../../hooks/Apollo/useLazyDocHubAdminQuery';
import { useAccount, useMsal } from '@azure/msal-react';
import { hasClaim } from '../utils/user';

const AdminContextProvider = (props: { children: React.ReactNode }): React.ReactElement => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [
    GetAdminInformation,
    { data: { adminInformation = null } = {}, loading }
  ] = useLazyDocHubAdminQuery<AdminInformationResponse>(GET_ADMIN_INFO);
  const [
    GetDocumentTypeConfiguration,
    { data: { getDocumentTypeConfigurations = null } = {} }
  ] = useLazyDocHubAdminQuery<DocumentConfigurationResponse>(GET_DOCUMENT_TYPE_CONFIG, {
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    const hasAccess = hasClaim(account, ['DocumentHubSuperAccess', 'DocumentHubAccess']);
    if (hasAccess) {
      GetAdminInformation();
      GetDocumentTypeConfiguration();
    }
  }, [GetAdminInformation, GetDocumentTypeConfiguration, account]);

  const [
    updateDocumentTypeConfigurationMutation,
    { loading: saveLoading }
  ] = useDocHubAdminMutation(UPDATE_DOCUMENT_TYPE_CONFIGURATION);

  const [deleteDocumentsMutation] = useDocHubAdminMutation(DELETE_DOCUMENT);

  const updateDocumentTypeConfiguration = async (
    documentType: string,
    configuration: DocumentTypeConfiguration
  ) => {
    const request: DocumentTypeConfiguration = {
      documentType: documentType,
      exportSubjectData: configuration.exportSubjectData,
      forgetMeActions: configuration.forgetMeActions,
      informationSecurityClassification: configuration.informationSecurityClassification,
      retentionPeriod: configuration.retentionPeriod,
      updateSubjectData: configuration.updateSubjectData,
      deletable: configuration.deletable
    };

    try {
      const { data } = await updateDocumentTypeConfigurationMutation({
        variables: {
          request
        }
      });
      if (data) {
        GetDocumentTypeConfiguration();
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const deleteDocument = async (id: string, version: number) => {
    const request: DeleteDocumentsRequest = {
      id,
      version
    };

    try {
      const { data } = await deleteDocumentsMutation({
        variables: {
          request
        }
      });
      if (!data) {
        throw new Error('Error deleting document');
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <AdminContext.Provider
      {...props}
      value={{
        loading,
        saving: saveLoading,
        documentTypes: adminInformation?.documentTypes.slice().sort() || [],
        entityTypes: adminInformation?.entityTypes || [],
        user: adminInformation?.adminUser,
        documentTypeConfiguration: getDocumentTypeConfigurations?.configurations || [],
        updateDocumentTypeConfiguration,
        deleteDocument
      }}
    />
  );
};

export { AdminContext, useAdminContext };
export default AdminContextProvider;
